import * as React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    required,
    ReferenceInput,
    FormDataConsumer,
    SelectInput,
    useGetList,
    ReferenceArrayInput,
    SelectArrayInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {useFormState} from 'react-final-form';
import { useDataProvider, Loading, Error } from 'react-admin';
import {useEffect, useState} from "react";

const FeatureInput = (data,...props) => {
    const { values } = useFormState();
    const choices = data.data[values.dataset_id] ? data.data[values.dataset_id].features : [];
    console.log(choices)
    return (
        <SelectInput
            optionText="name"
            source='target_feature'
            choices={choices}
        />
    );
};


export const ModelingCreate = (props) => {
    const dataProvider = useDataProvider();
    const { data, ids, loading, error } = useGetList('dataset')

    let data_input = null;
    if(!loading)
    {
        const choices = ids.map(id => data[id]);
        data_input = (<SelectInput label="Dataset" source="dataset_id" choices={choices} />)
    }
    return (
        <Create {...props}>
            <SimpleForm>
                {data_input}

                <SelectInput source="model_type" choices={[
                    {id: 'univariate', name: 'Univariate'},
                ]}/>

                <ReferenceInput label="Split methodology" source="split_method" reference="split_method">
                    <SelectInput optionText={'name'}/>
                </ReferenceInput>

                <ReferenceArrayInput source="metrics" reference="metric">
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>


                <FeatureInput
                    data={data}
                    {...props}
                />

            </SimpleForm>
        </Create>
    )
};