import * as React from "react";

import UploadFilesService from "./upload-files.service";

import { useFileUpload } from 'use-file-upload'
interface UploadProgress {
    total: number;
    loaded: number;
}

export const Uploader = (props) => {
    const [file, selectFile] = useFileUpload()
    const [progress, setProgress] = React.useState<UploadProgress | undefined>(undefined);


    const maxNumber = 69;

    const onProgress = (progress) => {
        setProgress(progress)
        console.log(progress)
    }

    // @ts-ignore
    const file_name = file ? file.name : null;

    return (
        <div>
            <button
                onClick={() => {
                    // Single File Upload

                    // @ts-ignore
                    selectFile({accept: '*',multiple: false}, ({ source, name, size, file }) => {
                        // file - is the raw File Object
                        console.log({ source, name, size, file })

                        const resp = UploadFilesService.upload(file, onProgress);
                        resp.then((response) => {
                            props.onChange({target: {value: response.data.id}})
                        })
                    })
                }}
            >
                Click to Upload
            </button>

            {file ? (
                <div>
                    <span> Name: {file_name} </span>

                    <div>
                        {progress ? (progress.loaded / progress.total) * 100 + '%' : null }
                    </div>
                </div>
            ) : (
                <span>No file selected</span>
            )}
        </div>
    )
}