import {List, Datagrid, TextField, EditButton, ShowButton, useRecordContext} from 'react-admin';
import * as React from "react";
import {Button} from "@material-ui/core";

export const DownloadButton = (props)  => {
    const record = useRecordContext(props);
    return (
        <Button
            onClick={() => {
                window.open(process.env.REACT_APP_API_URL + "/api/predictions/" + record.id + '/download/')
            }}
        >
            Download
        </Button>
    );
}

export const PredictionList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="model_instance_id" />
            <EditButton />
            <ShowButton />
            <DownloadButton />
        </Datagrid>
    </List>
);