import * as React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    required
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const DatasourceCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
        </SimpleForm>
    </Create>
);