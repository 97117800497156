/* eslint react/jsx-key: off */
import * as React from 'react';
import { Admin, Resource, RouteWithoutLayout } from 'react-admin';
import { render } from 'react-dom';
import { Route } from 'react-router-dom';

import CustomRouteLayout from './customRouteLayout';
import CustomRouteNoLayout from './customRouteNoLayout';
import i18nProvider from './i18nProvider';
import Layout from './Layout';

import datasources from './datasources';
import datasets from './datasets';
import modelings from './modelings'
import predictions from './predictions';

import { parseBool } from "./helpers";

import drfProvider, {
    fetchJsonWithAuthJWTToken,
    fetchJsonWithAuthToken,
    jwtTokenAuthProvider, tokenAuthProvider
} from "ra-data-django-rest-framework";
const useJWTAuth = parseBool(process.env.REACT_APP_USE_JWT_AUTH);
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
console.log(REACT_APP_API_URL);
let authProvider;
let dataProvider;
if (useJWTAuth) {
    console.log("Using rest_framework_simplejwt.authentication.JWTAuthentication");
    authProvider = jwtTokenAuthProvider({obtainAuthTokenUrl: "/api/token/"});
    dataProvider = drfProvider("/api", fetchJsonWithAuthJWTToken);
} else {
    console.log("Using rest_framework.authentication.TokenAuthentication");
    authProvider = tokenAuthProvider({obtainAuthTokenUrl: REACT_APP_API_URL + '/api/token-auth/'});
    dataProvider = drfProvider( REACT_APP_API_URL + "/api", fetchJsonWithAuthToken);
}

render(
    <React.StrictMode>
        <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            title="Example Admin"
            layout={Layout}
            customRoutes={[
                <RouteWithoutLayout
                    exact
                    path="/custom"
                    component={props => <CustomRouteNoLayout {...props} />}
                />,
                <Route
                    exact
                    path="/custom2"
                    component={props => <CustomRouteLayout {...props} />}
                />,
            ]}
        >
            {permissions => [
                /*<Resource name="posts" {...posts} />,
                <Resource name="comments" {...comments} />,
                permissions ? <Resource name="users" {...users} /> : null,
                <Resource name="tags" {...tags} />,*/

                <Resource name="datasource" {...datasources} />,
                <Resource name="dataset" {...datasets} />,
                <Resource name="model" {...modelings} />,

                <Resource name="split_method" />,
                <Resource name="modeling_intent" />,
                <Resource name="predictions" {...predictions} />,
                <Resource name="trained_model" />,
                <Resource name="metric" />
               /* <Resource name="datasource" list={ListGuesser} />*/
            ]}
        </Admin>
    </React.StrictMode>,
    document.getElementById('root')
);
