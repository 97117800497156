import * as React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    required
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const PredictionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="ID" source="id"/>
            <TextInput source="name" validate={required()}/>

        </SimpleForm>
    </Edit>
);