import {ModelingList} from "./ModelingList";
import BookIcon from "@material-ui/icons/Book";
import {ModelingEdit} from "./ModelingEdit";
import {ModelingCreate} from "./ModelingCreate";
import {ModelingShow} from "./ModelingShow";

export default {
    list: ModelingList,
    create: ModelingCreate,
    edit: ModelingEdit,
    show: ModelingShow,
    icon: BookIcon,
}