import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    ReferenceManyField,
    Datagrid,
    List,
    TabbedForm,
    FormTab, ShowButton, ArrayField
} from 'react-admin';
import { Link } from 'react-router-dom';
import {Button} from "@material-ui/core";

const CreateRelatedModel = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: '/model/create',
            state: { record: { dataset_id: record.id } },
        }}
    >
        Create a model
    </Button>
);

export const DatasetShow = (props) => (
    <Show {...props}>

            <TabbedForm warnWhenUnsavedChanges>
                <FormTab label="datasource.form.summary">
                    <TextField source="name"/>
                </FormTab>


                <FormTab label="datasource.form.features">
                    <ArrayField source="features">
                        <Datagrid>
                            <TextField source="name" />

                        </Datagrid>
                    </ArrayField>

                </FormTab>

                <FormTab label="datasource.form.models">
                    <ReferenceManyField label={'Datasets'} reference={'model'} target={'dataset_id'}>
                        <List {...props}>
                            <Datagrid>
                                <TextField source="id" />
                                <TextField source="name" />
                                <ShowButton />

                            </Datagrid>
                        </List>
                    </ReferenceManyField>
                    <CreateRelatedModel record={props} />

                </FormTab>
            </TabbedForm>

    </Show>
);