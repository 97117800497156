import {DatasetList} from "./DatasetList";
import BookIcon from "@material-ui/icons/Book";
import {DatasetEdit} from "./DatasetEdit";
import {DatasetCreate} from "./DatasetCreate";
import {DatasetShow} from "./DatasetShow";

export default {
    list: DatasetList,
    create: DatasetCreate,
    edit: DatasetEdit,
    show: DatasetShow,
    icon: BookIcon,
}