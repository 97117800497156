import {DatasourceList} from "./DatasourceList";
import BookIcon from "@material-ui/icons/Book";
import {DatasourceEdit} from "./DatasourceEdit";
import {DatasourceCreate} from "./DatasourceCreate";
import {DatasourceShow} from "./DatasourceShow";

export default {
    list: DatasourceList,
    create: DatasourceCreate,
    edit: DatasourceEdit,
    show: DatasourceShow,
    icon: BookIcon,
}