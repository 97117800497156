
import http from "./http-common";
import {tokenAuthProvider} from "ra-data-django-rest-framework";

class UploadFilesService {
    upload(file,onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);
        const token = localStorage.getItem('token');
        return http.post(process.env.REACT_APP_API_URL + "/api/file_upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Token " + token
            },
            onUploadProgress,
        });
    }

    getFiles() {
        return [];
    }
}

export default new UploadFilesService();