import {PredictionList} from "./PredictionList";
import BookIcon from "@material-ui/icons/Book";
import {PredictionEdit} from "./PredictionEdit";
import {PredictionCreate} from "./PredictionCreate";

export default {
    list: PredictionList,
    create: PredictionCreate,
    edit: PredictionEdit,
    icon: BookIcon,
}