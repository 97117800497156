import * as React from "react";
import {
    Create,
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    required, ReferenceField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {useField} from "react-final-form";
import UploadFiles from '../uploader'
import {Uploader} from "../components/uploader/Uploader";

const UploadFilesWrapper = (props) => {
    const {
        input: {onChange},
        meta: {touched, error}
    } = useField('file_id');

    return <Uploader onChange={onChange}/>
}


export const PredictionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <ReferenceField label="Model" source="model_instance_id" reference="trained_model">
                <TextField source="id" />
            </ReferenceField>

            <UploadFilesWrapper />
        </SimpleForm>
    </Create>
);