import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    EditButton,
    FileInput,
    required,
    FileField
} from 'react-admin';
import UploadFiles from '../uploader'
import RichTextInput from 'ra-input-rich-text';
import {useField} from "react-final-form";
import {Uploader} from "../components/uploader/Uploader";




const UploadFilesWrapper = (props) => {
    const {
        input: {onChange},
        meta: {touched, error}
    } = useField('file_id');

    return <Uploader onChange={onChange}/>
}

export const DatasetCreate = (props) => {



    return (<Create {...props}>
        <SimpleForm>
            <TextInput required={true} source="name"/>
            <ReferenceInput required={true} label="Datasource" source="datasource_id" reference="datasource">
                <SelectInput source="name"/>
            </ReferenceInput>

            <UploadFilesWrapper />

        </SimpleForm>
    </Create>)
};