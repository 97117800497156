import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    ReferenceManyField,
    Datagrid,
    List,
    useRecordContext,
    TabbedForm,
    SingleFieldList,
    SimpleList,
    FormTab, ShowButton, ChipField, ArrayField
} from 'react-admin';
import {Button} from "@material-ui/core";
import { Link } from 'react-router-dom';
import {DownloadButton} from "../predictions/PredictionList";


const CreatePredictionButton = (props)  => {
    const record = useRecordContext(props);
    console.log(record.model_instances);
    return record.model_instances[0] ? (
        <Button
            component={Link}
            to={{
                pathname: '/predictions/create',
                state: {record: {model_instance_id: record.model_instances[0].id }},
            }}
        >
            Create a prediction
        </Button>) : null;
};

const LinkToPredictions = ( props ) => {
    const record = useRecordContext(props);
    return record ? (
        <Button
            color="primary"
            component={Link}
            to={{
                pathname: '/predictions',
                search: `filter=${JSON.stringify({ model_instance_id: record.model_instances[0].id })}`,
            }}
        >
            View predictions
        </Button>
    ) : null;
};

const ModelShow = props => (
    <Show
        {...props}
        /* disable the app title change when shown */
        title=" "
    >
        <SimpleShowLayout>

            <LinkToPredictions source="model_instances.[0]" />

            <ArrayField source="model_instances.[0].evaluations">
                <SingleFieldList>
                    <ArrayField source="feature_evaluations">
                        <React.Fragment>
                            <Datagrid>
                                <TextField source="metric.key"/>
                                <TextField source="target_feature.name"/>
                                <TextField source="value"/>
                            </Datagrid>

                        </React.Fragment>
                    </ArrayField>

                </SingleFieldList>


            </ArrayField>

        </SimpleShowLayout>
    </Show>
);


export const ModelingShow = (props) => (
    <Show {...props}>

            <TabbedForm warnWhenUnsavedChanges>
                <FormTab label="model.form.summary">
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="dataset.name"/>
                    <TextField source="model_type.key"/>

                    <ArrayField source="target_features" >
                        <SingleFieldList>
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ArrayField>

                </FormTab>

                <FormTab label="datasource.form.models">
                    <ReferenceManyField label={'Models'} reference={'modeling_intent'} target={'base_intent_id'}>
                        <Datagrid expand={<ModelShow />}>
                            <TextField source="ai_model.ai_model.name"/>
                            <CreatePredictionButton />
                        </Datagrid>

                    </ReferenceManyField>

                </FormTab>



            </TabbedForm>

    </Show>
);