import {List, Datagrid, TextField, EditButton, ShowButton} from 'react-admin';
import * as React from "react";

export const DatasourceList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);