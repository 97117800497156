import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    ReferenceManyField,
    Datagrid,
    List,
    TabbedForm,
    FormTab, ShowButton
} from 'react-admin';
import { Link } from 'react-router-dom';
import {Button} from "@material-ui/core";

const CreateRelatedDataset = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: '/dataset/create',
            state: { record: { datasource_id: record.id } },
        }}
    >
        Add dataset
    </Button>
);

export const DatasourceShow = (props) => (
    <Show {...props}>

            <TabbedForm warnWhenUnsavedChanges>
                <FormTab label="datasource.form.summary">
                    <TextField source="name"/>
                </FormTab>


                <FormTab label="datasource.form.datasets">
                    <ReferenceManyField label={'Datasets'} reference={'dataset'} target={'datasource_id'}>
                        <Datagrid>
                            <TextField source="name"/>
                            <DateField source="created_at"/>
                            <ShowButton />
                        </Datagrid>

                    </ReferenceManyField>
                    <CreateRelatedDataset record={props} />

                </FormTab>
            </TabbedForm>

    </Show>
);